import React from "react";

const Documentation = ({ src }) => {
  console.log(src);
  return (
    <div>
      <iframe
        src={src}
        style={{
          border: "none",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
        title="Full Screen Iframe"
      ></iframe>
    </div>
  );
};

export default Documentation;

export function getDomainName(hostname) {
  let parts = hostname.split(".");
  if (parts.length >= 2) {
    return parts.slice(-2).join(".");
  } else {
    return hostname; // Return the full hostname if it doesn't have enough parts
  }
}
