import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Documentation, { getDomainName } from "./Documentation";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Documentation
              src={`https://app.${getDomainName(
                window.location.hostname
              )}/documentation`}
            />
          }
        />
        <Route
          path="/flap"
          element={
            <Documentation
              src={`https://app.${getDomainName(
                window.location.hostname
              )}/flap`}
            />
          }
        />
        <Route
          path="/gamable"
          element={
            <Documentation
              src={`https://dev.${getDomainName(
                window.location.hostname
              )}/gamable`}
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
